// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./wap-gaming-bg3.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./wap-gaming-bg4.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.app-container{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

}

.btngif {
  
  width: 200px;
  height: auto;
  cursor: pointer;
  
}

.reload{
  width: 100px;
}


@media screen and (max-width:600px) {
  .app-container{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});

  }
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";;AAEA;EACE,yDAA6C;EAC7C,4BAA4B;EAC5B,sBAAsB;EACtB,2BAA2B;EAC3B,aAAa;;EAEb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;AAErB;;AAEA;;EAEE,YAAY;EACZ,YAAY;EACZ,eAAe;;AAEjB;;AAEA;EACE,YAAY;AACd;;;AAGA;EACE;IACE,yDAA6C;;EAE/C;AACF","sourcesContent":["\n\n.app-container{\n  background-image: url('./wap-gaming-bg3.png');\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  height: 100vh;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n}\n\n.btngif {\n  \n  width: 200px;\n  height: auto;\n  cursor: pointer;\n  \n}\n\n.reload{\n  width: 100px;\n}\n\n\n@media screen and (max-width:600px) {\n  .app-container{\n    background-image: url('./wap-gaming-bg4.png');\n\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
